.person-wrapper {
    width: 100%;
    height: calc(100% - 45px);
    overflow: hidden;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    margin-top: 45px;
}

.person-wrapper .add-tool {
    height: 45px;
}

.person-wrapper .person-list {
    height: calc(100% - 90px);
    overflow-y: auto;
    margin: 5px 0;
}

.person-wrapper .confirm-tool {
    height: 45px;
}

.person-wrapper .my-list .spe .am-list-extra {
    flex-basis: initial;
}


.person-wrapper .list-wrapper {
    display: flex;
    width: 100%;
}

.person-wrapper .person-info {
    width: calc(100% - 60px);
}

.person-wrapper .check-wrapper {
    width: calc(100% - 70px);
}

.person-wrapper .op-edit {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.am-list-header {
    padding: 9px 15px !important;
}

.person-wrapper .am-list .am-list-item.am-checkbox-item .am-list-thumb .am-checkbox-inner {
    top: 45px !important;
    border-radius: 0 !important;
}

.person-wrapper .input-left-align {
    text-align: left;
}

.modal-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.modal-content {
    height: calc(100% - 40px);
}

.modal-footer {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
}

.person-wrapper .person-item-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.bc-modal-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.bc-modal-content {
    height: calc(100% - 80px);
}

.bc-modal-footer {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
}

.luggage-wrapper {
    height: calc(100% - 176px);
    overflow-y: auto;
}

.luggage-wrapper .am-input-label {
    width: 150px !important;
    overflow-x: auto !important;
}

.luggage-wrapper .am-list-item {
    padding-left: 8px !important;
}
