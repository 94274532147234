html,body{
  height: 100%;
  font-family: "Microsoft-YaHei";
  color: #333;
}
*{
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mt5 {
  margin-top: 5px;
}

.ml5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}
