.cpyy-wrpper {
    height: calc(100% - 45px);
    margin-top: 45px;
    width: 100%;
    overflow: hidden;
}

.cpyy-wrpper .am-tabs {
    height: calc(100% - 130px) !important;
}

.cpyy-wrpper .ticket-content {
    height: 100%;
    overflow: hidden;
}

.cpyy-wrpper .ticket-content .title {
    background-color: #ffffff;
}

.cpyy-wrpper .item-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.cpyy-wrpper .item-left {
    width: calc(100% - 80px);
    border-right: 1px solid #eee;
}

.cpyy-wrpper .item-right {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
}

.cpyy-wrpper .date-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    color: #1677ff;
    padding: 0 10px;
}

.cpyy-wrpper .am-list-item .am-list-line .am-list-content {
    display: flex;
}
.cpyy-wrpper .calendar-list .right {
    float: right;
}

.cpyy-wrpper .am-modal-body {
    height: calc(100% - 104px);
    overflow: auto;
}

.cpyy-wrpper .cpyy-modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.cpyy-wrpper .cpyy-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
}

.cpyy-wrpper .cpyy-content {
    height: calc(100% - 110px);
    width: 100%;
}

.cpyy-wrpper .ticket-list {
    height: calc(100% - 40px);
    overflow-y: auto;
}

.cpyy-wrpper .cpyy-footer {
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.cpyy-wrpper .am-modal-title {
    font-size: 16px !important;
}

.cpyy-wrpper .order-tips {
    padding-left: 10px;
    color: red;
    height: 130px;
    border-top: 1px solid #eee;
    font-size: 14px;
}

.tips-modal-wrapper {
    height: 100%;
}

.tips-modal-title {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    color: #108ee9;
    font-size: 16px;
}

.tips-modal-content {
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: red;
    font-size: 14px;
}

.tips-modal-footer {
    height: 30px;
}

