.his-ordered-wrapper {
    height: calc(100% - 45px);
    margin-top: 45px;
    width: 100%;
    overflow: hidden;
}

.his-ordered-wrapper .date-tool {
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 16px;
    margin: 5px 10px;
}

.his-ordered-wrapper .table-wrapper {
    height: calc(100% - 10px);
    margin-top: 10px;
}

.his-ordered-wrapper .date-label {
    width: 80px
}

.his-ordered-wrapper .date-show {
    width: calc(100% - 80px);
}

.his-ordered-wrapper .date-show .am-button {
    font-size: 15px !important;
}

.his-ordered-wrapper .item-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.his-ordered-wrapper .col1 {
    width: calc(100% - 150px);
    text-align: center;
    font-weight: bold;
}

.his-ordered-wrapper .col2 {
    width: 80px;
    text-align: center;
    font-weight: bold;
}

.his-ordered-wrapper .col3 {
    width: 70px;
    text-align: center;
    font-weight: bold;
}

.his-ordered-wrapper .yy-col1 {
    display: flex;
    width: calc(100% - 150px);
    align-items: flex-start;
    flex-direction: column;
}

.his-ordered-wrapper .yy-col2 {
    display: flex;
    width: 80px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
}

.his-ordered-wrapper .yy-col3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    font-size: 14px;
}

.his-ordered-wrapper .table-title {
    height: 88px;
}

.his-ordered-wrapper .cancel-tips {
    height: 17px;
    color: red;
    padding-left: 10px;
    margin-bottom: 10px;
}

.his-ordered-wrapper .table-content {
    height: calc(100% - 97px);
    overflow-y: auto;
    margin-top: 9px;
}

.his-ordered-wrapper .table-content .am-tag {
    height: 30px;
    line-height: 30px;
}
